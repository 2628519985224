import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';

interface Props {
  demo: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function Form({ demo, open, setOpen }: Props) {
  const [snack, setSnack] = useState<{
    message: string;
    open: boolean;
    severity: AlertColor | undefined;
  }>({
    message: '',
    open: false,
    severity: undefined,
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    message: '',
  });

  const handleSnackClose = () => {
    setSnack({
      message: '',
      open: false,
      severity: undefined,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log('test');
    e.preventDefault();

    try {
      const response = await fetch('/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          message: `${demo ? 'Booking a demo' : 'Enquire'}\n\nName: ${
            formData.name
          } \n\nEmail: ${formData.email} \n\n${
            demo ? 'Phone Number' : 'Message'
          }: ${formData.phone_number} ${
            !demo && `Phone Number: ${formData.message}`
          }`,
        }),
      });

      if (response.ok) {
        setSnack({
          message: 'Message sent successfully!',
          open: true,
          severity: 'success',
        });
        setFormData({ name: '', email: '', phone_number: '', message: '' });
        handleClose();
      } else {
        setSnack({
          message: 'Failed to send message. Please try again.',
          open: true,
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setSnack({
        message: 'There was an error sending your message.',
        open: true,
        severity: 'error',
      });
    }
  };

  return (
    <>
      {' '}
      <Snackbar
        open={snack.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>{demo ? 'Book a demo' : 'Get in touch!'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin='dense'
            name='name'
            label='Name'
            value={formData.name}
            onChange={handleChange}
            type='text'
            fullWidth
            variant='standard'
          />
          <TextField
            autoFocus
            required
            margin='dense'
            name='email'
            value={formData.email}
            onChange={handleChange}
            label='Email Address'
            type='email'
            fullWidth
            variant='standard'
          />
          {!demo && (
            <TextField
              autoFocus
              required
              margin='dense'
              name='message'
              value={formData.message}
              onChange={handleChange}
              label={'Phone Number'}
              rows={4}
              type='text'
              fullWidth
              variant='standard'
            />
          )}
          <TextField
            autoFocus
            required
            margin='dense'
            name='phone_number'
            multiline={!demo}
            value={formData.phone_number}
            onChange={handleChange}
            label={demo ? 'Phone Number' : 'Message'}
            rows={4}
            type='text'
            fullWidth
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type='submit'>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
